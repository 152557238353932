// Google

const gtagScript = document.createElement('script')
gtagScript.type = 'text/javascript'
gtagScript.setAttribute('async', 'true')
gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js')
document.head.appendChild(gtagScript)

window.dataLayer = window.dataLayer || []
function gtag() {
    dataLayer.push(arguments)
}
window.gtag = gtag

gtag('js', new Date())
process.env.GTAG_ID && gtag('config', process.env.GTAG_ID, { debug_mode: false })
process.env.GTAG_ADS_ID && gtag('config', process.env.GTAG_ADS_ID, { debug_mode: false, groups: 'ads' })

// Facebook

if (process.env.FB_PIXEL) {
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

    fbq('init', process.env.FB_PIXEL)
    fbq('track', 'PageView')
}
